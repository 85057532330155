const $ = jQuery;
import 'bootstrap/js/dist/dropdown';
import './wp-login.scss';
import { setLoginLinksGTM } from './gtm';

const DROPDOWN_WRAPPER_HTML =
	'<div class="dropdown-menu" id="dropdown-menu" aria-labelledby="language-switcher"></div>';

const ready = (callback) => {
	if ('loading' !== document.readyState) {
		callback();
	} else {
		document.addEventListener(`DOMContentLoaded`, callback);
	}
};

function createLoginWithElementorButton() {
	const loginText =
		`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
  			<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 22.5C6.70101 22.5 2 17.799 2 12C2 6.20101 6.70101 1.5 12.5 1.5C18.299 1.5 23 6.20101 23 12C23 17.799 18.299 22.5 12.5 22.5ZM12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3Z" fill="#0C0D0E"/>
 			<path d="M9 8H10.4998V15.5002H9V8Z" fill="#0C0D0E"/>
			<path d="M11.9996 8H16.4989V9.49976H11.9996V8Z" fill="#0C0D0E"/>
			<path d="M11.9996 10.9996H16.4989V12.4993H11.9996V10.9996Z" fill="#0C0D0E"/>
  			<path d="M11.9996 14.0005H16.4989V15.5002H11.9996V14.0005Z" fill="#0C0D0E"/>
		</svg>
		<span>Log in with Elementor</span>`
	;

	const $elementorLoginArea = $('<div id="e-login-area"></div>');

	$elementorLoginArea.append(
		`<a id="e-login-button" href="/.cloud/login_with_elementor" target="_blank">${loginText}</a>`,
	);

	$elementorLoginArea.append($('<div id="e-login-or">OR</div>'));

	$elementorLoginArea.prependTo('#loginform');
}

ready(() => {
	setLoginLinksGTM();
	createLoginWithElementorButton();
});
